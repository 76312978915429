
import { useMedia } from "@libraryComposables/useMedia";
import { computed } from "vue";

export default {
	name: "CoreBlockMedia",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const mediaSrc = computed(() => props.settings?.src);
		const { mediaType } = useMedia(mediaSrc);
		return {
			mediaType,
		};
	},
};
